
import {Options, setup, Vue} from 'vue-class-component'
import {list} from '@/composables/comments/list'

@Options({
  props: {
    agentId: Number,
  },
})
export default class Comments extends Vue {
  ctx = setup(list)
  agentId!: number
  text = ''


  mounted() {
    this.ctx.setFilterAgentId(this.agentId)
    this.ctx.fetch()
  }

  typing(el: any) {
    this.text = el.target.value
  }

  decodeHtml(html: string) {
    let text = document.createElement('textarea')
    text.innerHTML = html
    return text.textContent ? text.textContent : ''
  }

  send() {
    console.log(this.text)
    this.ctx.store(this.agentId, this.text).then(() => {
      this.text = ''
    })
  }
}
