
import {AgentItemInterface} from '@/composables/agents/item'
import Comments from '@/components/agents/profile/Comments.vue'
import Actions from '@/components/agents/profile/Actions.vue'
import Info from '@/components/agents/profile/Info.vue'
import {Options, Vue} from 'vue-class-component'


@Options({
  props: {
    item: Object
  },
  components: {
    Comments, Actions, Info
  },
})

export default class Profile extends Vue {
  item!: AgentItemInterface
}
