
import {onBeforeRouteUpdate} from 'vue-router'
import {item} from '@/composables/agents/item'
import useChat from '@/composables/agents/chat'
import {Options, setup, Vue} from 'vue-class-component'
import Profile from '@/components/agents/profile/Profile.vue'
import Orders from '@/components/agents/Orders.vue'
import Balance from '@/components/agents/Balance.vue'
import Chat from '@/components/agents/Chat.vue'
import useEmitter from '@/composables/useEmitter'

enum TabNames {
  PROFILE = 'profile',
  ORDERS = 'orders',
  BALANCE = 'balance',
  CHAT = 'chat',
}

@Options({
  props: {
    agent: {
      type: String,
      require: true,
    },
  },
  components: {
    Profile, Orders, Balance, Chat
  },
})
export default class Card extends Vue {
  ctx = setup(() => {
    const ctx = item()
    const chatCtx = useChat()
    onBeforeRouteUpdate((to) => {
      ctx.getEntityById(parseInt(to.params.agent + ''))
    })
    const {emitter} = useEmitter()
    return {
      ...ctx,
      ...chatCtx,
      emitter
    }
  })
  agent = ''
  tab = TabNames.PROFILE
  tabNames = TabNames

  created(): void {
    this.ctx.getEntityById(parseInt(this.agent))
    this.ctx.setAgentId(parseInt(this.agent))
    this.ctx.sync()
    this.ctx.emitter.on('agent:' + this.agent + ':messages:status', this.ctx.setUnreadMsgCount)
  }

  copy(): boolean {
    navigator.clipboard.writeText(this.ctx.entity.passport.fullname)
    return false
  }

  beforeUnmount(): void {
    this.ctx.emitter.off('agent:' + this.agent + ':messages:status')
  }

}
