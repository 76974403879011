import { ref } from '@vue/reactivity'
import { AxiosError } from 'axios'
import { showError } from '@/composables/utils'
import AgentsService from '@/services/AgentsService'
import { Agent, Comment } from '@/composables/agents/agents-models'
import { ResponseData } from '@/services/types'
import { Meta } from './list'

export interface AgentItemInterface {
    // getters
    entity: Agent;
    isEntityLoading: Agent;

    // mutators
    setEntity: (agent: Agent) => void

    // actions
    changeVerifyStatus: (flag: boolean) => void
    changeBanStatus: () => void
    changeWatchStatus: () => void
    changeModerateStatus: () => void
    spam: (flag: boolean) => void
    savePassport: () => Promise<ResponseData>
    saveEmail: () => Promise<ResponseData>
    saveMaxstoreContactInMonth: () => Promise<ResponseData>
}

export function item() {
    const entity = ref({} as Agent)
    const isEntityLoading = ref(false)
    const commentsList = ref({} as Comment)
    const commentsMeta = ref({} as Meta)
    const isCommentsLoading = ref(false)
    const historyItems = ref([])
    const historyMeta = ref({} as Meta)
    const isHistoryLoading = ref(false)
    const ordersList = ref([])
    const ordersListMeta = ref({} as Meta)
    const isOrdersListLoading = ref(false)
    const isActionsLoading = ref(false)

    function getEntityById(entityId: number): void {
        isEntityLoading.value = true
        AgentsService.getById(entityId)
            .then((response: ResponseData) => {
                entity.value = response.data.data
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isEntityLoading.value = false)
    }

    function getComments(): void {
        isCommentsLoading.value = true
        AgentsService.getComments(entity.value.general.id)
            .then((response: ResponseData) => {
                commentsList.value = response.data.data.items
                commentsMeta.value = response.data.data.meta
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isCommentsLoading.value = false)
    }

    function savePassport(): Promise<ResponseData> {
        isCommentsLoading.value = true
        return AgentsService.updatePass(entity.value.general.id, entity.value.passport)
    }

    function saveEmail(): Promise<ResponseData> {
        isCommentsLoading.value = true
        return AgentsService.updateEmail(entity.value.general.id, entity.value.general.email)
    }
    function saveMaxstoreContactInMonth(): Promise<ResponseData> {
        isCommentsLoading.value = true
        return AgentsService.updateMaxstoreContactInMonth(entity.value.general.id, entity.value.general.max_store_contacts_in_month)

    }

    function addComment(text: string): void {
        isCommentsLoading.value = true
        AgentsService.addComment(entity.value.general.id, text)
            .then(() => {
                getComments()
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isCommentsLoading.value = false)
    }

    function getHistory(): void {
        isHistoryLoading.value = true
        AgentsService.getHistory(entity.value.general.id)
            .then((response: ResponseData) => {
                historyItems.value = response.data.data.items
                historyMeta.value = response.data.data.meta
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isHistoryLoading.value = false)
    }

    function getOrders(): void {
        isOrdersListLoading.value = true
        AgentsService.getOrders(entity.value.general.id)
            .then((response: ResponseData) => {
                ordersList.value = response.data.data.items
                ordersListMeta.value = response.data.data.meta
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isOrdersListLoading.value = false)
    }


    function changeVerifyStatus(flag: boolean): void {
        isActionsLoading.value = true
        AgentsService.verify(entity.value.general.id, flag)
            .then((response: ResponseData) => {
                entity.value.passport.is_passport_verified = response.data.data.is_verified
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isActionsLoading.value = false)
    }

    function changeBanStatus(): void {
        isActionsLoading.value = true
        AgentsService.ban(entity.value.general.id)
            .then((response: ResponseData) => {
                entity.value.general.is_banned = response.data.data.is_banned
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isActionsLoading.value = false)
    }

    function changeWatchStatus(): void {
        isActionsLoading.value = true
        AgentsService.watch(entity.value.general.id)
            .then((response: ResponseData) => {
                entity.value.general.is_watch = response.data.data.is_watch
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isActionsLoading.value = false)
    }

    function setEntity(agent: Agent) {
        entity.value = agent
    }

    function spam(flag: boolean) {
        isActionsLoading.value = true
        AgentsService.spam(entity.value.general.id, flag)
            .then((response: ResponseData) => {
                entity.value.passport.is_excluded = response.data.data.is_excluded
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isActionsLoading.value = false)
    }

    return {
        // getters
        entity,
        isEntityLoading,
        commentsList,
        commentsMeta,
        isCommentsLoading,
        historyItems,
        historyMeta,
        isHistoryLoading,
        ordersList,
        ordersListMeta,
        isOrdersListLoading,
        isActionsLoading,

        // mutations
        setEntity,

        // actions
        getEntityById,
        getComments,
        addComment,
        getHistory,
        getOrders,
        changeVerifyStatus,
        changeBanStatus,
        changeWatchStatus,
        spam,
        savePassport,
        saveEmail,
        saveMaxstoreContactInMonth
    }
}
