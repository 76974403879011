import http from '@/plugins/http-common'
import {OrderByVariants} from './constants'
import {ResponseData} from './types'

export type GetListParams = {
    limit: null | number,
    offset: null | number,
    order_by: null | OrderByVariants,
    sort_by: null | string,
    agent_id: null | number,
}

class CommentsService {
    getList = (params: GetListParams): Promise<ResponseData> => http.get('/comments', {params})
    store = (agentId: number, text: string): Promise<ResponseData> => http.post('/comments', {agent_id: agentId, text})
}

export default new CommentsService()