import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "columns"
}
const _hoisted_2 = { class: "column is-full-mobile is-two-fifths-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd" }
const _hoisted_3 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Actions = _resolveComponent("Actions")!
  const _component_Comments = _resolveComponent("Comments")!
  const _component_Info = _resolveComponent("Info")!

  return (_ctx.item.entity && _ctx.item.entity.general)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Actions, { item: _ctx.item }, null, 8, ["item"]),
          _createVNode(_component_Comments, {
            class: "box",
            agentId: _ctx.item.entity.general.id
          }, null, 8, ["agentId"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Info, { item: _ctx.item }, null, 8, ["item"])
        ])
      ]))
    : _createCommentVNode("", true)
}