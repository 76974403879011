export enum ModalType {
    INFO = 'info',
    WARNING = 'warning',
    DANGER = 'danger',
    SUCCESS = 'success'
}


export type Modal = {
    title: string|null,
    text: string,
    type: ModalType|null,
    onsuccess: CallableFunction,
    onexit: CallableFunction|null
}