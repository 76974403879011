
import {AgentItemInterface} from '@/composables/agents/item'
import {ModalType} from '@/composables/core/modal'
import {Options, setup, Vue} from 'vue-class-component'
import useEmitter from '@/composables/useEmitter'


@Options({
  props: {
    item: Object
  },
})

export default class Profile extends Vue {

  ctx = setup(() => ({...useEmitter()}))

  item!: AgentItemInterface

  verify(): void {
    const modal = {
      text: 'Подтвердить паспортные данные?',
      type: ModalType.INFO,
      onsuccess: () => {
        this.item.changeVerifyStatus(true)
      },
    }

    this.ctx.emitter.emit('modal:show', modal)
  }

  remoderate(): void {
    const modal = {
      text: 'Запросить повторно паспортные данные?',
      type: ModalType.INFO,
      onsuccess: () => {
        this.item.changeVerifyStatus(false)
      },
    }

    this.ctx.emitter.emit('modal:show', modal)
  }

  ban(): void {
    const modal = {
      text: this.item.entity.general.is_banned ? 'Точно разблокировать?' : 'Установить блокировку аккаунта?',
      type: ModalType.WARNING,
      onsuccess: this.item.changeBanStatus,
    }

    this.ctx.emitter.emit('modal:show', modal)
  }

  spam() {
    const modal = {
      text: this.item.entity.passport.is_excluded ? 'Вернуть агента?' : 'Скрыть агента?',
      type: ModalType.WARNING,
      onsuccess: () => {
        this.item.spam(!this.item.entity.passport.is_excluded)
      },
    }

    this.ctx.emitter.emit('modal:show', modal)
  }

  watching(): void {
    const modal = {
      text: this.item.entity.general.is_watch ? 'Перестать наблюдать за агентом?' : 'Наблюдать за агентом?',
      type: ModalType.DANGER,
      onsuccess: this.item.changeWatchStatus,
    }

    this.ctx.emitter.emit('modal:show', modal)
  }

}
