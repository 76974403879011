
import {Options, setup, Vue} from 'vue-class-component'
import {LimitPageVariants, LimitPageDefault} from '@/composables/constants'
import {OrdersModels} from '@/composables/orders/types'
import ModalCard from '@/components/orders/ModalCard.vue'
import {list, SortingDefault} from '@/composables/orders/list'
import {Sorting} from '@/services/types'
import {positiveInt} from '@/composables/mask'

@Options({
  props: {
    agentId: Number,
  },
  components: {ModalCard},
})
export default class Orders extends Vue {
  showOptions = false
  page = 1
  limit = LimitPageDefault.value
  sorting: Sorting = SortingDefault
  agentId!: number
  selectedOrder: null | OrdersModels = null;

  ctx = setup(() => {
    return {...list(), LimitPageVariants}
  })

  created(): void {
    this.clear()
  }


  closeModalWindow() {
    this.selectedOrder = null
  }



  /**
   * Общая часть
   */

  // триггер на обновление таблицы для новой страницы
  async changePage(): Promise<void> {
    this.page = positiveInt(this.page)
    await this.fetch()
  }

  // Применить фильтры
  async apply() {
    this.page = 1
    await this.fetch()
  }

  // Сбросить фильтры до дефолта
  async clear() {
    this.ctx.reset()
    this.ctx.setFilterAgentId(this.agentId)
    this.cancel()
    await this.fetch()
  }

  // Отменить введенные фильтры
  cancel() {
    this.page = this.ctx.meta.offset / this.ctx.meta.limit + 1
    this.limit = this.ctx.meta.limit
    this.sorting = this.ctx.sorting

    this.close()
  }

  close() {
    this.showOptions = false
  }

  scroll(){
    document.getElementById('table')?.scrollTo({top: 0, behavior: 'smooth'})
  }

  async fetch(){
    this.ctx.setLimit(this.limit)
    this.ctx.setPage(this.page)
    this.ctx.setSorting(this.sorting)

    await this.ctx.fetch()
    this.close()
    this.scroll()
  }
}
