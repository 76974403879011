
import {Options, setup, Vue} from 'vue-class-component'
import chat, {ChatMessage} from '@/composables/agents/chat'
import Popper from 'vue3-popper'
import {File as FileModel} from '@/composables/file/models'
import {file} from '@/composables/file'
import useEmitter from '@/composables/useEmitter'

@Options({
    props: {
        agentId: Number
    },
    components: {
        Popper,
    },
})

export default class Chat extends Vue {

    ctx = setup(() => {
        const {emitter} = useEmitter()
        let attachments: File[] = []
        return {...file(), ...chat(), emitter, attachments}
    })
    agentId!: number
    text = ''

    selectPreparedMessage(message: string): void {
        this.text = message
        document.getElementById('text-input')?.focus()
    }

    mounted(): void {
        this.initScroll()
        this.ctx.setAgentId(this.agentId)
        this.ctx.fetch()
    }

    onFileChanged(event: any): void {
      let files: FileList = event.target.files
      for (var i = 0; i < files.length; i++) {
        this.ctx.attachments.push(files.item(i) as File)
      }
      this.$forceUpdate()
    }

    initScroll(): void {
        const wrapper = document.getElementById('messages-wrapper') || undefined
        if (wrapper) {
            wrapper.scrollTo(0, wrapper.scrollHeight)
            wrapper.onscroll = (r: Event) => {
                const scroll = r.target as HTMLElement
                if (scroll.scrollTop < 20) {
                    this.ctx.fetchNext()
                }
            }
        }

    }

    editMessage(message: any) {
      message.edit = true
    }

    saveMessage(message: any) {
      this.ctx.update(message.id,message.text)
      message.edit = false
    }

    decodeHtml(html: string): string {
      let text = document.createElement('textarea')
      text.innerHTML = html
      return text.textContent ? text.textContent : ''
    }

    getName(msg: ChatMessage) {
        if (msg.from_agent) {
            return 'Агент'
        } else {
            return msg.user && msg.user.name ? msg.user.name : 'Неизвестно'
        }
    }

    send() {
        if (this.text) {
            this.ctx.send(this.text, this.ctx.attachments)
            this.text = ''
            this.ctx.attachments = []
        }
    }

    attachFile() {
      const elem = this.$refs.fileInput as HTMLElement
      elem.click()
    }

    deleteFile(index:File) {
      this.ctx.attachments.splice(this.ctx.attachments.indexOf(index), 1)
      this.$forceUpdate()
    }

    handleAttach(attach: FileModel) {
      const link = document.createElement('a')
      link.href = attach.url
      link.setAttribute('download', attach.name)
      link.click()
    }

    read() {
        this.ctx.read()
        this.ctx.emitter.emit('agent:' + this.agentId + ':messages:status', this.ctx.unreadMessagesCount)
    }
}
